
import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AdminPanel from './page/admin/page/AdminPanel';
import ClientPanel from './ClientPanel ';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import checkUserIP from './services/userRedirect';

const App = () => {
  // useEffect(async() =>{
  //   const response = await checkUserIP();
  //   if(response === "IN"){
  //     window.location.href = "https://in.clubmall.com";
  //   } else if (response === "GB"){
  //     window.location.href = "https://eu.clubmall.com";
  //   } else {
  //     window.location.href = "https://clubmall.com";
  //   }
  // },[])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<ClientPanel />} />
        <Route path="/admin/*" element={<AdminPanel />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
