import React, { useState,useContext } from "react";
import { Home, Users, Bell, ShoppingBag, ShoppingCart } from "lucide-react";
import { CartContext } from '../context/CartContext';
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Is_Login } from '../helper/IsLogin'

const BottomNav = () => {
  const [activeNav, setActiveNav] = useState("For You"); // State for active navigation
  const { cartList,localCart } = useContext(CartContext);
  const isLoggedIn = Is_Login();

  return (
    <motion.nav
      className="bottom-nav position-fixed bottom-0 start-0 end-0"
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      style={{ zIndex: 999999, background: "#000000" }}
    >
      <div className="d-flex justify-content-around align-items-center py-3">
        <NavItem
          icon={<Home className="icon-lg" />}
          label="For You"
          active={activeNav === "For You"}
          link="/"
          setActive={setActiveNav}
        />
        <NavItem
          icon={<Users className="icon-lg" />}
          label="Live"
          active={activeNav === "Live"}
          link="/"
          setActive={setActiveNav}
        />
        <NavItem
          icon={<ShoppingBag className="icon-lg" />}
          label="Shop"
          active={activeNav === "Shop"}
          link="/trending"
          setActive={setActiveNav}
        />
        <NavItem
          icon={
            <div className="position-relative">
              <ShoppingCart className="icon-lg" />
              {isLoggedIn ?
                  <span
                    className="badge bg-danger position-absolute top-0 start-100 translate-middle"
                    style={{ fontSize: "0.7rem" }}
                    >
                        {cartList.list ? cartList.list?.length : 0}
                  </span> :
                  <span
                    className="badge bg-danger position-absolute top-0 start-100 translate-middle"
                    style={{ fontSize: "0.7rem" }}
                  >
                  {localCart.items ? localCart.items?.length : 0}
                  </span> 
                  }
            </div>
          }
          label="Cart"
          active={activeNav === "Cart"}
          link="/cart"
          setActive={setActiveNav}
        />
        <NavItem
          icon={<Bell className="icon-lg" />}
          label="Alerts"
          active={activeNav === "Alerts"}
          link="/profile"
          setActive={setActiveNav}
        />
      </div>
    </motion.nav>
  );
};

const NavItem = ({ link, icon, label, active, setActive }) => {
  const navigate = useNavigate();
  return (
    <motion.button
      className={`btn d-flex flex-column align-items-center ${
        active ? "text-white" : "text-muted"
      }`}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        setActive(label); // Update active state
        navigate(link);
      }}
    >
      <motion.div className={`${active ? "text-white" : ""}`} whileHover={{ y: -2 }}>
        {icon}
      </motion.div>
      <motion.span
        className={`small mt-1 ${active ? "text-white" : "text-muted"}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {label}
      </motion.span>
    </motion.button>
  );
};

export default BottomNav;
